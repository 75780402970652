var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 h-100 login-box overflow-y-auto bg-center bg-no-repeat p-relative text-color"},[_c('span',{staticClass:"p-absolute close-button",on:{"click":_vm.goBack}},[_c('van-icon',{attrs:{"name":"arrow-left"}})],1),_c('div',{staticClass:"logo-box bg-center bg-no-repeat p-absolute"}),_c('div',{staticClass:"login-section w-100"},[_c('div',{staticClass:"form-field font-regular"},[_c('van-form',{staticClass:"m-t-10"},[_c('div',{staticClass:"box p-relative bg-center bg-no-repeat bg-size-100",class:{
                        'is-fast-login': _vm.isLoginMode && _vm.isRegister,
                        'is-login': _vm.isLoginMode,
                        'is-register': _vm.isRegister && !_vm.isResetPassword,
                        'is-reset': _vm.isRegister && _vm.isResetPassword
                    }},[(_vm.isLoginMode)?_c('div',{staticClass:"tabs flex align-center text-center w-100"},[_c('span',{staticClass:"flex-1 tab",on:{"click":_vm.passwordLogin}},[_vm._v("密码登录")]),_c('span',{staticClass:"flex-1 tab",on:{"click":_vm.fastRegister}},[_vm._v("立即注册")])]):_c('div',{staticClass:"font-16 p-t-25 text-white font-500 text-center"},[_c('span',[_vm._v(_vm._s(_vm.isResetPassword ? '忘记密码' : '注册'))])]),_c('input-with-icon',{staticClass:"m-t-30",class:{
                            'm-b-25': !_vm.isRegister,
                            'm-b-10': _vm.isRegister,
                        }},[_c('span',{staticClass:"flex align-center",attrs:{"slot":"icon"},slot:"icon"},[_c('svg-icon',{attrs:{"icon-class":"phone"}}),_c('span',{staticClass:"m-l-10 font-13"},[_vm._v("+86")])],1),_c('van-field',{staticClass:"p-l-10 p-r-25 text-color",attrs:{"maxlength":"11","placeholder":"请输入手机号"},model:{value:(_vm.form.account.value),callback:function ($$v) {_vm.$set(_vm.form.account, "value", $$v)},expression:"form.account.value"}})],1),_c('div',{attrs:{"id":"captcha"}}),(_vm.showCode)?_c('input-with-icon',{class:{
                            'm-b-25': !_vm.isRegister,
                            'm-b-10': _vm.isRegister,
                        },attrs:{"has-suffix":true}},[_c('svg-icon',{attrs:{"slot":"icon","icon-class":"safe"},slot:"icon"}),_c('van-field',{staticClass:"m-l-10",attrs:{"name":"验证码","placeholder":"请输入验证码"},model:{value:(_vm.form.code.value),callback:function ($$v) {_vm.$set(_vm.form.code, "value", $$v)},expression:"form.code.value"}}),_c('template',{slot:"suffix"},[_c('span',{staticClass:"font-13 font-regular",on:{"click":_vm.getValidateCode}},[_vm._v(_vm._s(_vm.codeString))])])],2):_vm._e(),(!_vm.fastLogin || _vm.isResetPassword)?_c('input-with-icon',{class:{
                            'm-b-25': !_vm.isRegister,
                            'm-b-10': _vm.isRegister,
                        }},[_c('svg-icon',{attrs:{"slot":"icon","icon-class":"password"},slot:"icon"}),_c('van-field',{staticClass:"p-l-10 p-r-25",attrs:{"type":"password","name":"密码","placeholder":_vm.isResetPassword ? '请输入新密码' : '请输入密码'},model:{value:(_vm.form.password.value),callback:function ($$v) {_vm.$set(_vm.form.password, "value", $$v)},expression:"form.password.value"}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isRegister),expression:"!isRegister"}],staticClass:"w-100 forget p-absolute text-center font-12 font-400 text-white",class:{
                            'visibility-hidden': !_vm.isLoginMode
                        }},[_c('div',[_c('span',{on:{"click":_vm.forgetPassword}},[_vm._v(" 忘记密码 ")])])])],1),_c('div',{staticClass:"m-t-25 submit-button"},[_c('van-button',{staticClass:"font-16",attrs:{"block":"","type":"default","round":""},nativeOn:{"click":function($event){return _vm.beforeSubmit.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.buttonTitle))])],1)]),_c('div',{staticClass:"protocol flex align-center m-t-25 flex-direction-c"},[_c('van-checkbox',{scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{staticClass:"img-icon",attrs:{"src":props.checked ? _vm.checkedLogo : _vm.checkEmpty}})]}}]),model:{value:(_vm.agreeUs),callback:function ($$v) {_vm.agreeUs=$$v},expression:"agreeUs"}},[_c('span',{staticClass:"text-999 font-12 font-400"},[_vm._v("我已阅读并同意")])]),_c('div',{staticClass:"font-12 font-400 link p-t-5 p-b-5"},[_c('span',{on:{"click":_vm.goToProtocol}},[_vm._v("《用户协议》")]),_c('span',{on:{"click":_vm.goToPolicy}},[_vm._v("《隐私政策》")])])],1),_c('div',{staticClass:"logo2 w-100 text-center"},[_c('img',{attrs:{"src":_vm.logo2,"alt":""}})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }